<template>
  <body ontouchstart="">
    <div style="text-align: center;">
      <van-image style="margin: 25% 35% 3% 35%;" width="5rem" height="5rem" fit="cover" :src="newImg" />
      <span style="margin: auto 35% auto 35%;">支付成功!</span>
      <van-button type="primary" style="margin: 15% 5% auto 5% ; width: 90%; height: 40%;" color="#57c4b7" round size="large"
        to="/PayL">返回</van-button>
    </div>
  </body>
</template>

<script>
import ajax from '../../lib/ajax';
  export default {
    data() {
      return {
        newImg: require('../../assets/PaySuccess.png'),
      }
    },
    created(){
      this.onload();
    },
    methods:{
      onload(){
        let time = 3;
        setInterval(() => {
          setTimeout(() => {
            if (time > 0) {
              ajax.get("/Api/LoginService/Test")
              .then(res => {
                let type = res.data.result.state;
                console.log("轮询结果为："+type);
                if (type===2) {
                  time = 0;
                }
              }).catch(err => {
                console.log("错误结果为："+err);
              })
              --time;
              console.log(time);
            }else{
              clearTimeout(this.time);
            }
          }, 0);
        }, 3000);
      }
    }
  }
</script>

<style>
</style>
